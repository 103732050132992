import { CustomerProfileApi } from "../../Customer/Edit/CustomerProfile.Api";
import { CustomerListDataGridStore } from "../../Customer/List/CustomerList.GridStore";
import { OverviewCardManager } from "./OverviewCardManager";


export const OVERVIEW_CARD_TYPES = {
  BAR: 'BAR',
  DYNAMIC_CARD: 'DYNAMIC_CARD',
  CARD_CONTAINER: 'CARD_CONTAINER',
  CARD: 'CARD',
  BEST: 'BEST',
  PIE: 'PIE',
  DONUT: 'DONUT',
  LINE: 'LINE',
  FUNNEL: 'FUNNEL',
  GRID: 'GRID',
  CUSTOM: 'CUSTOM',
  WORDCLOUD: 'WORDCLOUD',
  GAUGE: 'GAUGE',
  COLUMN: 'COLUMN',
  VENN: 'VENN',
  WORLD_MAP: 'WORLD_MAP',
  REAL_TIME_TRAFFIC: 'REAL_TIME_TRAFFIC',

}


export const OVERVIEW_CARD_FILTER= {
  IF_HAS_ORDERS:'IF_HAS_ORDERS',
  IF_HAS_BASKETS:'IF_HAS_BASKETS',
  IF_HAS_PRODUCTS:'IF_HAS_PRODUCTS'
}

export const OVERVIEW_CARD_CATEGORY = {
  ANALYTICS:'analytics',
  ECOMMERCE:'ecommerce',
  CUSTOMER:'customer',
  GENERIC:'generic',
}

export const OVERVIEW_CARD_CATEGORY_LABELS = {
  analytics:'Analytics',
  ecommerce:'eCommerce',
  customer:'Customer',
  generic:'Generic'
}


export const OVERVIEW_PAGE_TYPE = {
  ANALYTICS:'analytics',
  ECOMMERCE:'ecommerce',
  CUSTOMER:'customer',
  DASHBOARD:'dashboard',
  ANY:'any'
}







export const CARD_TYPE_LIST_ITEMS = {
  BEST: {
    description: 'Best valued records as list. Top 10 records only',
    icon: 'list'
  },
  CARD: {
    description: 'A card showing total precalculated value.',
    icon: 'pin'
  },
  DYNAMIC_CARD: {
    description: 'A card showing total recent value.',
    icon: 'pin'
  },
  CARD_CONTAINER: {
    description: 'Configurable card container. Contains cards.',
    icon: 'pin'
  },
  BAR: {
    description: 'An horizontal bar chart.',
    icon: 'bar_chart'
  },
  PIE: {
    description: 'A pie chart showing percentages.',
    icon: 'pie_chart'
  },
  DONUT: {
    description: 'A donut chart.',
    icon: 'donut_large'
  },
  LINE: {
    description: 'A line chart.',
    icon: 'stacked_line_chart'
  },
  FUNNEL: {
    description: 'A funnel chart.',
    icon: 'change_history'
  },
  GRID: {
    description: 'A data table showing paged records.',
    icon: 'table'
  },
  CUSTOM: {
    description: 'User defined chart item',
    icon: 'person'
  },
  WORDCLOUD: {
    description: 'Word cloud, presents words in different sizes based on how many times they repeated in a text',
    icon: 'mist'
  },
  GAUGE: {
    description: 'Gauge chart',
    icon: 'speed'
  },
  COLUMN: {
    description: 'A vertical bar chart.',
    icon: 'bar_chart'
  },
  VENN: {
    description: 'Venn chart visualize data with common points',
    icon: 'bubble_chart'
  },
  WORLD_MAP: {
    description: 'World map to visualize data based on country/province',
    icon: 'bubble_chart'
  },
  REAL_TIME_TRAFFIC: {
    description: 'Realtime analytics data',
    icon: 'bubble_chart'
  }
};

export const CARD_TYPE_MIN_SIZES = {
  BEST: {
    size: { minWidth: 1, minHeight: 8 },
  },
  CARD: {
    size: { minWidth: 1, minHeight: 3 },
  },
  DYNAMIC_CARD: {
    size: { minWidth: 1, minHeight: 2 },
  },
  CARD_CONTAINER: {
    size: { minWidth: 1, minHeight: 4 },
  },
  BAR: {
    size: { minWidth: 1, minHeight: 8 },
  },
  PIE: {
    size: { minWidth: 1, minHeight: 8 },
  },
  DONUT: {
    size: { minWidth: 1, minHeight: 8 },
  },
  LINE: {
    size: { minWidth: 2, minHeight: 4 },
  },
  FUNNEL: {
    size: { minWidth: 2, minHeight: 6 },
  },
  GRID: {
    size: { minWidth: 2, minHeight: 12 },
  },
  CUSTOM: {
    size: { minWidth: 1, minHeight: 4 },
  },
  WORDCLOUD: {
    size: { minWidth: 2, minHeight: 4 },
  },
  GAUGE: {
    size: { minWidth: 1, minHeight: 4 },
  },
  COLUMN: {
    size: { minWidth: 2, minHeight: 6 },
  },
  VENN: {
    size: { minWidth: 1, minHeight: 4 },
  },
  WORLD_MAP: {
    size: { minWidth: 2, minHeight: 12 },
  },
  REAL_TIME_TRAFFIC: {
    size: { minWidth: 2, minHeight: 12 },
  }
};



export const OVERVIEW_CARD_REALTIME_CHART_TYPES = {
  ACTIVE_CUSTOMERS: 'ACTIVE_CUSTOMERS',
  SEGMENT_CARD: 'SEGMENT_CARD',
  TAG_CARD: 'TAG_CARD',
  SEGMENT_REPORT: 'SEGMENT_REPORT',
}

export const OVERVIEW_CARD_PRECALCULATED_CHART_TYPES = {
  // CUSTOMER_INSIGHT:'CUSTOMER_INSIGHT',
  CUSTOMER_ENGAGEMENT: 'CUSTOMER_ENGAGEMENT',
  CUSTOMER_SEGMENT_BEST: 'CUSTOMER_SEGMENT_BEST',
  CUSTOMER_SOURCE: 'CUSTOMER_SOURCE',
  CUSTOMER_REFERRAL: 'CUSTOMER_REFERRAL',
  CUSTOMER_TAG_BEST: 'CUSTOMER_TAG_BEST',
  CUSTOMER_TOP: 'CUSTOMER_TOP',
  // CUSTOMER_INTERACTION:'CUSTOMER_INTERACTION',
  CUSTOMERS_BY_RFM: 'CUSTOMERS_BY_RFM',
  CUSTOMERS_BY_SEGMENT: 'CUSTOMERS_BY_SEGMENT',
  CUSTOMERS_BY_TAG: 'CUSTOMERS_BY_TAG', 

  CUSTOMER_INTERACTION_REGISTER: 'CUSTOMER_INTERACTION_REGISTER',
  CUSTOMER_INTERACTION_MEMBERSHIP: 'CUSTOMER_INTERACTION_MEMBERSHIP',
  CUSTOMER_INTERACTION_INTERACTED: 'CUSTOMER_INTERACTION_INTERACTED',
  CUSTOMER_INTERACTION_REGISTER_ORDER: 'CUSTOMER_INTERACTION_REGISTER_ORDER',
  CUSTOMER_INTERACTION_COMMENT: 'CUSTOMER_INTERACTION_COMMENT',
  CUSTOMER_INTERACTION_ADD_BASKET_ORDER: 'CUSTOMER_INTERACTION_ADD_BASKET_ORDER',


  // CUSTOMERS_BY_SESSIONS: 'CUSTOMERS_BY_SESSIONS',
  // CUSTOMERS_BY_ORDERS: 'CUSTOMERS_BY_ORDERS',
  CUSTOMERS_BY_LANGUAGE: 'CUSTOMERS_BY_LANGUAGE',
  CUSTOMERS_BY_RFM_SCORE: 'CUSTOMERS_BY_RFM_SCORE',
  CUSTOMERS_BY_RECENCY: 'CUSTOMERS_BY_RECENCY',
  CUSTOMERS_BY_FREQUENCY: 'CUSTOMERS_BY_FREQUENCY',
  CUSTOMERS_BY_MONEYTARY: 'CUSTOMERS_BY_MONEYTARY',
  CUSTOMERS_BY_DEVICE: 'CUSTOMERS_BY_DEVICE',


  CUSTOMERS_BY_LOCATION: 'CUSTOMERS_BY_LOCATION',
  CUSTOMERS_BY_BROWSER: 'CUSTOMERS_BY_BROWSER',
  CUSTOMERS_BY_COUNTRY: 'CUSTOMERS_BY_COUNTRY',
  CUSTOMERS_BY_PROVINCE: 'CUSTOMERS_BY_PROVINCE',
  CUSTOMERS_BY_REFERRER: 'CUSTOMERS_BY_REFERRER',
  CUSTOMERS_BY_REFERRAL_CODE: 'CUSTOMERS_BY_REFERRAL_CODE',
  CUSTOMERS_BY_ORDERED_ITEM: 'CUSTOMERS_BY_ORDERED_ITEM',
  CUSTOMERS_BY_SESSION: 'CUSTOMERS_BY_SESSION',
  CUSTOMERS_BY_ORDER: 'CUSTOMERS_BY_ORDER',
  CUSTOMERS_BY_MONETARY: 'CUSTOMERS_BY_MONETARY',
  CUSTOMERS_BY_ORDER_STATUS: 'CUSTOMERS_BY_ORDER_STATUS',
  CUSTOMERS_BY_CHAT_SESSION: 'CUSTOMERS_BY_CHAT_SESSION',
  CUSTOMERS_BY_FAILED_ORDER: 'CUSTOMERS_BY_FAILED_ORDER',
  CUSTOMERS_BY_SUCCESSFUL_ORDER: 'CUSTOMERS_BY_SUCCESSFUL_ORDER',
  CUSTOMERS_BY_BASKET_TOTAL: 'CUSTOMERS_BY_BASKET_TOTAL',
  CUSTOMERS_BY_ORDERED_ITEM_NUMBER: 'CUSTOMERS_BY_ORDERED_ITEM_NUMBER',




  TOTAL_CUSTOMERS: 'TOTAL_CUSTOMERS',
  REGISTERED_CUSTOMERS: 'REGISTERED_CUSTOMERS',
  LOGGEDIN_CUSTOMERS: 'LOGGEDIN_CUSTOMERS',
  LEFT_CUSTOMERS: 'LEFT_CUSTOMERS',
  CONVERTED_CUSTOMERS: 'CONVERTED_CUSTOMERS',
  ORDERED_CUSTOMERS: 'ORDERED_CUSTOMERS',
  NEW_CUSTOMERS: 'NEW_CUSTOMERS',
  RETURNING_CUSTOMERS: 'RETURNING_CUSTOMERS',
  TWO_TIMES_BUYERS: 'TWO_TIMES_BUYERS',




  ECOMMERCE_PRODUCT_BEST: 'ECOMMERCE_PRODUCT_BEST',
  CUSTOMER_RETURNED: 'CUSTOMER_RETURNED',

  CUSTOMER_MOST_SPENT: 'CUSTOMER_MOST_SPENT',









  ECOMMERCE_REVENUE: 'ECOMMERCE_REVENUE',

  ECOMMERCE_ORDERS: 'ECOMMERCE_ORDERS',
  ECOMMERCE_USERS: 'ECOMMERCE_USERS',
  ECOMMERCE_REGISTERED_USERS: 'ECOMMERCE_REGISTERED_USERS',
  ECOMMERCE_REGISTERED_ORDERED_USERS: 'ECOMMERCE_REGISTERED_ORDERED_USERS',
  ECOMMERCE_REGISTERED_ABANDONED_USERS: 'ECOMMERCE_REGISTERED_ABANDONED_USERS',
  ECOMMERCE_REGISTERED_PAID_USERS: 'ECOMMERCE_REGISTERED_PAID_USERS',
  ECOMMERCE_ORDER_FUNNEL: 'ECOMMERCE_ORDER_FUNNEL',
  ECOMMERCE_USER_FUNNEL: 'ECOMMERCE_USER_FUNNEL',
  ECOMMERCE_ORDERS_BY_DAY: 'ECOMMERCE_ORDERS_BY_DAY',
  ECOMMERCE_ALL_ORDERS_BY_DAY:'ECOMMERCE_ALL_ORDERS_BY_DAY',
  ECOMMERCE_REVENUE_BY_DAY: 'ECOMMERCE_REVENUE_BY_DAY',
  ECOMMERCE_REGISTERED_USERS_BY_DAY: 'ECOMMERCE_REGISTERED_USERS_BY_DAY',
  ECOMMERCE_REGISTERED_ORDERED_USERS_BY_DAY: 'ECOMMERCE_REGISTERED_ORDERED_USERS_BY_DAY',
  ECOMMERCE_REGISTERED_ABANDONED_USERS_BY_DAY: 'ECOMMERCE_REGISTERED_ABANDONED_USERS_BY_DAY',
  ECOMMERCE_REGISTERED_PAID_USERS_BY_DAY: 'ECOMMERCE_REGISTERED_PAID_USERS_BY_DAY',
  ECOMMERCE_ORDERS_BY_HOURS: 'ECOMMERCE_ORDERS_BY_HOURS',
  ECOMMERCE_REVENUE_BY_HOURS: 'ECOMMERCE_REVENUE_BY_HOURS',
  ECOMMERCE_REGISTERED_USERS_BY_HOURS: 'ECOMMERCE_REGISTERED_USERS_BY_HOURS',
  ECOMMERCE_REGISTERED_NOT_ORDERED_USERS_BY_DAY: 'ECOMMERCE_REGISTERED_NOT_ORDERED_USERS_BY_DAY',
  ECOMMERCE_FAILED_ORDERS: 'ECOMMERCE_FAILED_ORDERS',

  ECOMMERCE_ORDERS_PENDING_PAYMENT:'ECOMMERCE_ORDERS_PENDING_PAYMENT',
  ECOMMERCE_ORDERS_PENDING_SHIPMENT:'ECOMMERCE_ORDERS_PENDING_SHIPMENT',
  ECOMMERCE_ORDERS_REFUNDED:'ECOMMERCE_ORDERS_REFUNDED',
  ECOMMERCE_ORDERS_CANCELLED:'ECOMMERCE_ORDERS_CANCELLED',
  ECOMMERCE_ORDERS_DELIVERED:'ECOMMERCE_ORDERS_DELIVERED',
  ECOMMERCE_ORDERS_DELIVERED_OLD:'ECOMMERCE_ORDERS_DELIVERED_OLD',

   

  ECOMMERCE_REVENUE_PENDING_PAYMENT:'ECOMMERCE_REVENUE_PENDING_PAYMENT',
  ECOMMERCE_REVENUE_PENDING_SHIPMENT:'ECOMMERCE_REVENUE_PENDING_SHIPMENT',
  ECOMMERCE_REVENUE_REFUNDED:'ECOMMERCE_REVENUE_REFUNDED',
  ECOMMERCE_REVENUE_CANCELLED:'ECOMMERCE_REVENUE_CANCELLED',
  ECOMMERCE_REVENUE_DELIVERED:'ECOMMERCE_REVENUE_DELIVERED',
  ECOMMERCE_REVENUE_DELIVERED_OLD:'ECOMMERCE_REVENUE_DELIVERED_OLD', 

  ECOMMERCE_AVRG_REVENUE_PENDING_PAYMENT:'ECOMMERCE_AVRG_REVENUE_PENDING_PAYMENT',
  ECOMMERCE_AVRG_REVENUE_PENDING_SHIPMENT:'ECOMMERCE_AVRG_REVENUE_PENDING_SHIPMENT',
  ECOMMERCE_AVRG_REVENUE_REFUNDED:'ECOMMERCE_AVRG_REVENUE_REFUNDED',
  ECOMMERCE_AVRG_REVENUE_CANCELLED:'ECOMMERCE_AVRG_REVENUE_CANCELLED',
  ECOMMERCE_AVRG_REVENUE_DELIVERED:'ECOMMERCE_AVRG_REVENUE_DELIVERED',
  ECOMMERCE_AVRG_REVENUE_DELIVERED_OLD:'ECOMMERCE_AVRG_REVENUE_DELIVERED_OLD',

 



  ECOMMERCE_ITEMS_IN_TRANSIT:'ECOMMERCE_ITEMS_IN_TRANSIT',
  ECOMMERCE_ORDERS_IN_TRANSIT:'ECOMMERCE_ORDERS_IN_TRANSIT',
  ECOMMERCE_REVENUE_IN_TRANSIT:'ECOMMERCE_REVENUE_IN_TRANSIT',
  ECOMMERCE_AVRG_REVENUE_IN_TRANSIT:'ECOMMERCE_AVRG_REVENUE_IN_TRANSIT',


  ECOMMERCE_ORDERS_NET:'ECOMMERCE_ORDERS_NET',
  ECOMMERCE_ORDERS_ALL:'ECOMMERCE_ORDERS_ALL',
  ECOMMERCE_ORDERS_SUCCESSFUL:'ECOMMERCE_ORDERS_SUCCESSFUL', 
  ECOMMERCE_ORDERS_FAILED:'ECOMMERCE_ORDERS_FAILED',
 
  ECOMMERCE_ITEMS_ALL:'ECOMMERCE_ITEMS_ALL',
  ECOMMERCE_ITEMS_NET:'ECOMMERCE_ITEMS_NET',
  ECOMMERCE_ITEMS_SUCCESSFUL:'ECOMMERCE_ITEMS_SUCCESSFUL',
  ECOMMERCE_ITEMS_FAILED:'ECOMMERCE_ITEMS_FAILED',
  ECOMMERCE_ITEMS_PENDING_PAYMENT:'ECOMMERCE_ITEMS_PENDING_PAYMENT',
  ECOMMERCE_ITEMS_PENDING_SHIPMENT:'ECOMMERCE_ITEMS_PENDING_SHIPMENT',
  ECOMMERCE_ITEMS_REFUNDED:'ECOMMERCE_ITEMS_REFUNDED',
  ECOMMERCE_ITEMS_CANCELLED:'ECOMMERCE_ITEMS_CANCELLED',
  ECOMMERCE_ITEMS_DELIVERED:'ECOMMERCE_ITEMS_DELIVERED',
  ECOMMERCE_ITEMS_DELIVERED_OLD:'ECOMMERCE_ITEMS_DELIVERED_OLD',
   
  
  ECOMMERCE_REVENUE_NET:'ECOMMERCE_REVENUE_NET',
  ECOMMERCE_REVENUE_ALL:'ECOMMERCE_REVENUE_ALL',
  ECOMMERCE_REVENUE_SUCCESSFUL:'ECOMMERCE_REVENUE_SUCCESSFUL', 
  ECOMMERCE_REVENUE_FAILED:'ECOMMERCE_REVENUE_FAILED',
  

  ECOMMERCE_AVRG_REVENUE_NET:'ECOMMERCE_AVRG_REVENUE_NET',
  ECOMMERCE_AVRG_REVENUE_ALL:'ECOMMERCE_AVRG_REVENUE_ALL',
  ECOMMERCE_AVRG_REVENUE_SUCCESSFUL:'ECOMMERCE_AVRG_REVENUE_SUCCESSFUL', 
  ECOMMERCE_AVRG_REVENUE_FAILED:'ECOMMERCE_AVRG_REVENUE_FAILED',
 
 



  ANALYTICS_VISIT_BY_TIME: 'ANALYTICS_VISIT_BY_TIME',
  ANALYTICS_VISIT_BY_REFERRER: 'ANALYTICS_VISIT_BY_REFERRER',
  ANALYTICS_VISIT_BY_DEVICE_TYPE: 'ANALYTICS_VISIT_BY_DEVICE_TYPE',
  ANALYTICS_VISIT_BY_SCREEN_SIZE: 'ANALYTICS_VISIT_BY_SCREEN_SIZE',
  ANALYTICS_VISIT_BY_COUNTRY: 'ANALYTICS_VISIT_BY_COUNTRY',
  ANALYTICS_VISIT_BY_BROWSER: 'ANALYTICS_VISIT_BY_BROWSER',
  ANALYTICS_VISIT_BY_OS: 'ANALYTICS_VISIT_BY_OS',
  ANALYTICS_VISIT_BY_MAIN_LANGUAGE: 'ANALYTICS_VISIT_BY_MAIN_LANGUAGE',
  ANALYTICS_VISIT_BY_MAJOR_LANGUAGE: 'ANALYTICS_VISIT_BY_MAJOR_LANGUAGE',
  ANALYTICS_VISIT_BY_LANGUAGES: 'ANALYTICS_VISIT_BY_LANGUAGES',
 
  ANALYTICS_VISIT_BY_UTM_SOURCE_UNIQUE:'ANALYTICS_VISIT_BY_UTM_SOURCE_UNIQUE',
  ANALYTICS_VISIT_BY_UTM_CAMPAIGN_UNIQUE:'ANALYTICS_VISIT_BY_UTM_CAMPAIGN_UNIQUE',
  ANALYTICS_VISIT_BY_UTM_MEDIUM_UNIQUE:'ANALYTICS_VISIT_BY_UTM_MEDIUM_UNIQUE',
  ANALYTICS_VISIT_BY_REFERRER_CATEGORY_UNIQUE:'ANALYTICS_VISIT_BY_REFERRER_CATEGORY_UNIQUE',


  ANALYTICS_INTERACTION: 'ANALYTICS_INTERACTION',




  ANALYTICS_VISITS: 'ANALYTICS_VISITS',
  ANALYTICS_UNIQUE_VISIT: 'ANALYTICS_UNIQUE_VISIT',
  ANALYTICS_HUMAN_VISIT: 'ANALYTICS_HUMAN_VISIT',
  ANALYTICS_BOT_VISIT: 'ANALYTICS_BOT_VISIT',
  ANALYTICS_UNIQUE_MOBILE_VISIT: 'ANALYTICS_UNIQUE_MOBILE_VISIT',
  ANALYTICS_UNIQUE_DESKTOP_VISIT: 'ANALYTICS_UNIQUE_DESKTOP_VISIT',
  ANALYTICS_HUMAN_DESKTOP_VISIT: 'ANALYTICS_HUMAN_DESKTOP_VISIT',
  ANALYTICS_UNIQUE_HUMAN_MOBILE_VISIT: 'ANALYTICS_UNIQUE_HUMAN_MOBILE_VISIT',
  ANALYTICS_UNIQUE_HUMAN_DESKTOP_VISIT: 'ANALYTICS_UNIQUE_HUMAN_DESKTOP_VISIT',
  ANALYTICS_BOT_MOBILE_VISIT: 'ANALYTICS_BOT_MOBILE_VISIT',
  ANALYTICS_BOT_DESKTOP_VISIT: 'ANALYTICS_BOT_DESKTOP_VISIT',
  ANALYTICS_UNIQUE_BOT_MOBILE_VISIT: 'ANALYTICS_UNIQUE_BOT_MOBILE_VISIT',
  ANALYTICS_UNIQUE_BOT_DESKTOP_VISIT: 'ANALYTICS_UNIQUE_BOT_DESKTOP_VISIT',



ANALYTICS_INTERACTED: 'ANALYTICS_INTERACTED',
ANALYTICS_UNIQUE_DESKTOP_REFERRED_VISIT: 'ANALYTICS_UNIQUE_DESKTOP_REFERRED_VISIT',
ANALYTICS_UNIQUE_MOBILE_REFERRED_VISIT: 'ANALYTICS_UNIQUE_MOBILE_REFERRED_VISIT',
ANALYTICS_UNIQUE_DESKTOP_DIRECT_VISIT: 'ANALYTICS_UNIQUE_DESKTOP_DIRECT_VISIT',
ANALYTICS_UNIQUE_MOBILE_DIRECT_VISIT: 'ANALYTICS_UNIQUE_MOBILE_DIRECT_VISIT',
ANALYTICS_UNIQUE_DIRECT_VISIT: 'ANALYTICS_UNIQUE_DIRECT_VISIT',
ANALYTICS_UNIQUE_REFERRED_VISIT: 'ANALYTICS_UNIQUE_REFERRED_VISIT',
ANALYTICS_DIRECT_VISIT: 'ANALYTICS_DIRECT_VISIT',
ANALYTICS_REFERRED_VISIT: 'ANALYTICS_REFERRED_VISIT',
ANALYTICS_REGISTER: 'ANALYTICS_REGISTER',
ANALYTICS_MEMBERSHIP: 'ANALYTICS_MEMBERSHIP',
ANALYTICS_CONVERTED: 'ANALYTICS_CONVERTED',
ANALYTICS_VISIT_TIME: 'ANALYTICS_VISIT_TIME',
ANALYTICS_INTERACTIONS: 'ANALYTICS_INTERACTIONS',
ANALYTICS_HUMAN_MOBILE_VISIT: 'ANALYTICS_HUMAN_MOBILE_VISIT', 


ANALYTICS_ACTIVE_VISITS_OVER_TIME: 'ANALYTICS_ACTIVE_VISITS_OVER_TIME', 
ANALYTICS_VISIT_MAP: 'ANALYTICS_VISIT_MAP', 




  // ECOMMERCE_REVENUE: 'ECOMMERCE_REVENUE',
  // ECOMMERCE_ORDER:'ECOMMERCE_ORDER',

  // ECOMMERCE_TOTAL_REVENUE:'ECOMMERCE_TOTAL_REVENUE',
  // ECOMMERCE_TOTAL_ORDER:'ECOMMERCE_TOTAL_ORDER',
  // ECOMMERCE_NEW_BASKET:'ECOMMERCE_NEW_BASKET',
  // ECOMMERCE_ABANDONED_BASKET:'ECOMMERCE_ABANDONED_BASKET',




  // ECOMMERCE_REGISTER_BY_DAY:'ECOMMERCE_REGISTER_BY_DAY',
  // ECOMMERCE_REGISTER_PAY_BY_DAY:'ECOMMERCE_REGISTER_PAY_BY_DAY',
  // ECOMMERCE_REGISTER_ABANDONED_BY_DAY:'ECOMMERCE_REGISTER_ABANDONED_BY_DAY'




}
export const OVERVIEW_CARD_CHART_TYPES = {

  ...OVERVIEW_CARD_REALTIME_CHART_TYPES,
  ...OVERVIEW_CARD_PRECALCULATED_CHART_TYPES
}

export const OVERVIEW_CARD_CHART_SETTINGS = {


  ANALYTICS_VISIT_MAP: () => { return {} },

  ANALYTICS_INTERACTION: () => { return {} },

  ANALYTICS_VISIT_BY_TIME: () => { return {} },
  ANALYTICS_VISIT_BY_REFERRER: () => { return {} },
  ANALYTICS_VISIT_BY_DEVICE_TYPE: () => { return {} },
  ANALYTICS_VISIT_BY_SCREEN_SIZE: () => { return {} },
  ANALYTICS_VISIT_BY_COUNTRY: () => { return {} },
  ANALYTICS_VISIT_BY_BROWSER: () => { return {} },
  ANALYTICS_VISIT_BY_OS: () => { return {} },
  ANALYTICS_VISIT_BY_MAIN_LANGUAGE: () => { return {} },
  ANALYTICS_VISIT_BY_MAJOR_LANGUAGE: () => { return {} },
  ANALYTICS_VISIT_BY_LANGUAGES: () => { return {} },
  ANALYTICS_VISIT_BY_UTM_SOURCE_UNIQUE: () => { return {} },


 
    ANALYTICS_INTERACTED: () => {
        return {
            tooltip: "Interacted sessions",
            value: 'interacted',
            measure: 'sessions',
            addChart: false
        }
    },
    ANALYTICS_UNIQUE_DESKTOP_REFERRED_VISIT: () => {
        return {
            tooltip: "Unique desktop referred visits",
            value: 'unique_desktop_referred_visit',
            measure: 'visits',
            addChart: true
        }
    },
    ANALYTICS_UNIQUE_MOBILE_REFERRED_VISIT: () => {
        return {
            tooltip: "Unique mobile referred visits",
            value: 'unique_mobile_referred_visit',
            measure: 'visits',
            addChart: true
        }
    },
    ANALYTICS_UNIQUE_DESKTOP_DIRECT_VISIT: () => {
        return {
            tooltip: "Unique desktop direct visits",
            value: 'unique_desktop_direct_visit',
            measure: 'visits',
            addChart: true
        }
    },
    ANALYTICS_UNIQUE_MOBILE_DIRECT_VISIT: () => {
        return {
            tooltip: "Unique mobile direct visits",
            value: 'unique_mobile_direct_visit',
            measure: 'visits',
            addChart: true
        }
    },
    ANALYTICS_UNIQUE_DIRECT_VISIT: () => {
        return {
            tooltip: "Unique direct visits",
            value: 'unique_direct_visit',
            measure: 'visits',
            addChart: true
        }
    },
    ANALYTICS_UNIQUE_REFERRED_VISIT: () => {
        return {
            tooltip: "Unique referred visits",
            value: 'unique_referred_visit',
            measure: 'visits',
            addChart: true
        }
    },
    ANALYTICS_DIRECT_VISIT: () => {
        return {
            tooltip: "Direct visits",
            value: 'direct_visit',
            measure: 'visits',
            addChart: true
        }
    },
    ANALYTICS_REFERRED_VISIT: () => {
        return {
            tooltip: "Referred visits",
            value: 'referred_visit',
            measure: 'visits',
            addChart: true
        }
    },
    ANALYTICS_REGISTER: () => {
        return {
            tooltip: "Registrations",
            value: 'register',
            measure: 'registrations',
            addChart: true
        }
    },
    ANALYTICS_MEMBERSHIP: () => {
        return {
            tooltip: "Memberships",
            value: 'membership',
            measure: 'memberships',
            addChart: true
        }
    },
    ANALYTICS_CONVERTED: () => {
        return {
            tooltip: "Converted users",
            value: 'converted',
            measure: 'conversions',
            addChart: true
        }
    },
    ANALYTICS_VISIT_TIME: () => {
        return {
            tooltip: "Average visit time",
            value: 'visit_time',
            measure: 'time',
            addChart: true
        }
    },
    ANALYTICS_INTERACTIONS: () => {
        return {
            tooltip: "Interactions",
            value: 'interaction',
            measure: 'interactions',
            addChart: true
        }
    }, 




  ANALYTICS_VISITS: () => {
    return {
      tooltip: "One time buyers",
      value: 'visit',
      measure: 'visit',
      addChart: false
    }
  },

  ANALYTICS_UNIQUE_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'unique_visit',
      measure: 'visit',
      addChart: false
    }
  },

  ANALYTICS_HUMAN_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'human_visit',
      measure: 'customers',
      addChart: false
    }
  },

  ANALYTICS_BOT_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'bot_visit',
      measure: 'customers',
      addChart: false
    }
  },


  ANALYTICS_UNIQUE_MOBILE_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'unique_mobile_visit',
      measure: 'customers',
      addChart: false
    }
  },

  ANALYTICS_UNIQUE_DESKTOP_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'unique_desktop_visit',
      measure: 'customers',
      addChart: false
    }
  },


  ANALYTICS_HUMAN_DESKTOP_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'human_desktop_visit',
      measure: 'customers',
      addChart: false
    }
  },



  ANALYTICS_UNIQUE_HUMAN_MOBILE_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'unique_human_mobile_visit',
      measure: 'customers',
      addChart: false
    }
  },



  ANALYTICS_UNIQUE_HUMAN_DESKTOP_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'unique_human_desktop_visit',
      measure: 'customers',
      addChart: false
    }
  },



  ANALYTICS_BOT_MOBILE_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'bot_mobile_visit',
      measure: 'customers',
      addChart: false
    }
  },


  ANALYTICS_BOT_DESKTOP_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'bot_desktop_visit',
      measure: 'customers',
      addChart: false
    }
  },



  ANALYTICS_UNIQUE_BOT_MOBILE_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'unique_bot_mobile_visit',
      measure: 'customers',
      addChart: false
    }
  },



  ANALYTICS_UNIQUE_BOT_DESKTOP_VISIT: () => {
    return {
      tooltip: "One time buyers",
      value: 'unique_bot_desktop_visit',
      measure: 'customers',
      addChart: false
    }
  },



  ECOMMERCE_USERS: () => {
    return {
      tooltip: "Total users",
      value: "users",
      measure: 'users',
      addChart: false
    }
  },

  ECOMMERCE_REGISTERED_PAID_USERS: () => {
    return {
      tooltip: "Total registered & paid",
      value: "registered_paid",
      measure: 'users',
      addChart: false
    }
  },

  ECOMMERCE_REGISTERED_ABANDONED_USERS: () => {
    return {
      tooltip: "Total registered & abandoned",
      value: "registered_abandoned",
      measure: 'users',
      addChart: false
    }
  },

  ECOMMERCE_REGISTERED_ORDERED_USERS: () => {
    return {
      tooltip: "Total registered & ordered",
      value: "registered_ordered",
      measure: 'users',
      addChart: false
    }
  },

  ECOMMERCE_REGISTERED_USERS: () => {
    return {
      tooltip: "Total registered",
      value: "registered",
      measure: 'users',
      addChart: false
    }
  },

  ECOMMERCE_REVENUE: () => {
    return {
      tooltip: "Total revenue",
      value: "successful_total",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
 

  

  ECOMMERCE_ORDERS: () => {
    return {
      tooltip: "Orders",
      value: "orders",
      measure: 'orders',
      addChart: false
    }
  },






  ECOMMERCE_ORDERS_ALL: () => {
    return {
      tooltip: "All orders",
      value: "all",
      measure: 'orders',
      addChart: false
    }
  },

  ECOMMERCE_ORDERS_SUCCESSFUL: () => {
    return {
      tooltip: "Successful orders",
      value: "successful",
      measure: 'orders',
      addChart: false
    }
  },

  ECOMMERCE_ORDERS_FAILED: () => {
    return {
      tooltip: "Failed orders",
      value: "failed",
      measure: 'orders',
      addChart: false
    }
  },
  ECOMMERCE_ORDERS_NET: () => {
    return {
      tooltip: "Net orders",
      value: "net",
      measure: 'orders',
      addChart: false
    }
  },

  ECOMMERCE_ORDERS_PENDING_PAYMENT: () => {
    return {
      tooltip: "Pending Payment",
      value: "pending_payment",
      measure: 'orders',
      addChart: false
    }
  },
  ECOMMERCE_ORDERS_PENDING_SHIPMENT: () => {
    return {
      tooltip: "Pending Shipment",
      value: "pending_shipment",
      measure: 'orders',
      addChart: false
    }
  },
  ECOMMERCE_ORDERS_IN_TRANSIT: () => {
    return {
      tooltip: "In Transit",
      value: "in_transit",
      measure: 'orders',
      addChart: false
    }
  },
  ECOMMERCE_ORDERS_CANCELLED: () => {
    return {
      tooltip: "Cancelled",
      value: "cancelled",
      measure: 'orders',
      addChart: false
    }
  },
  ECOMMERCE_ORDERS_REFUNDED: () => {
    return {
      tooltip: "Refunded",
      value: "refunded",
      measure: 'orders',
      addChart: false
    }
  },
  ECOMMERCE_ORDERS_DELIVERED: () => {
    return {
      tooltip: "Delivered",
      value: "delivered",
      measure: 'orders',
      addChart: false
    }
  },

  ECOMMERCE_ORDERS_DELIVERED_OLD: () => {
    return {
      tooltip: "Num or Orders delivered min. 15 days ago",
      value: "delivered_old_15days",
      measure: 'orders',
      addChart: false
    }
  },










  ECOMMERCE_ITEMS_ALL: () => {
    return {
      tooltip: "total num of items bbelongs to Total num of all ordered items",
      value: "all",
      measure: 'items',
      addChart: false
    }
  },

  ECOMMERCE_ITEMS_SUCCESSFUL: () => {
    return {
      tooltip: "Total num of items bbelongs to successful orders",
      value: "successful",
      measure: 'items',
      addChart: false
    }
  },

  ECOMMERCE_ITEMS_FAILED: () => {
    return {
      tooltip: "Total num of items bbelongs to Failed orders",
      value: "failed",
      measure: 'items',
      addChart: false
    }
  },
  ECOMMERCE_ITEMS_NET: () => {
    return {
      tooltip: "Total num of items bbelongs to Net orders",
      value: "net",
      measure: 'items',
      addChart: false
    }
  },

  ECOMMERCE_ITEMS_PENDING_PAYMENT: () => {
    return {
      tooltip: "Total num of items bbelongs to Pending Payment",
      value: "pending_payment",
      measure: 'items',
      addChart: false
    }
  },
  ECOMMERCE_ITEMS_PENDING_SHIPMENT: () => {
    return {
      tooltip: "Total num of items bbelongs to Pending Shipment",
      value: "pending_shipment",
      measure: 'items',
      addChart: false
    }
  },
  ECOMMERCE_ITEMS_IN_TRANSIT: () => {
    return {
      tooltip: "Total num of items bbelongs to In Transit",
      value: "in_transit",
      measure: 'items',
      addChart: false
    }
  },
  ECOMMERCE_ITEMS_CANCELLED: () => {
    return {
      tooltip: "Total num of items bbelongs to Cancelled",
      value: "cancelled",
      measure: 'items',
      addChart: false
    }
  },
  ECOMMERCE_ITEMS_REFUNDED: () => {
    return {
      tooltip: "Total num of items bbelongs to Refunded",
      value: "refunded",
      measure: 'items',
      addChart: false
    }
  },
  ECOMMERCE_ITEMS_DELIVERED: () => {
    return {
      tooltip: "Total num of items bbelongs to Delivered",
      value: "delivered",
      measure: 'items',
      addChart: false
    }
  },

  ECOMMERCE_ITEMS_DELIVERED_OLD: () => {
    return {
      tooltip: "Total num of items bbelongs to Num or Orders delivered min. 15 days ago",
      value: "delivered_old_15days",
      measure: 'items',
      addChart: false
    }
  },


 
 





  ECOMMERCE_REVENUE_ALL: () => {
    return {
      tooltip: "Total Income",
      value: "all",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },

  ECOMMERCE_REVENUE_SUCCESSFUL: () => {
    return {
      tooltip: "Revenue from successful orders",
      value: "successful",
      measure:window.currentAccount.currency,
      addChart: false
    }
  },

  ECOMMERCE_REVENUE_FAILED: () => {
    return {
      tooltip: "Revenue from failed orders",
      value: "failed",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_REVENUE_NET: () => {
    return {
      tooltip: "Revenue from net orders",
      value: "net",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_REVENUE_PENDING_PAYMENT: () => {
    return {
      tooltip: "Revenue from pending payment orders",
      value: "pending_payment",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_REVENUE_PENDING_SHIPMENT: () => {
    return {
      tooltip: "Pending Shipment Revenue",
      value: "pending_shipment",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_REVENUE_CANCELLED: () => {
    return {
      tooltip: "Cancelled Revenue",
      value: "cancelled",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_REVENUE_REFUNDED: () => {
    return {
      tooltip: "Refunded Revenue",
      value: "refunded",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_REVENUE_DELIVERED: () => {
    return {
      tooltip: "Delivered Revenue",
      value: "delivered",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },

  ECOMMERCE_REVENUE_IN_TRANSIT: () => {
    return {
      tooltip: "Revenue from orders on the way",
      value: "in_transit",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_REVENUE_DELIVERED_OLD: () => {
    return {
      tooltip: "Revenue from orders delivered min. 15 days ago",
      value: "delivered_old_15days",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
 











  ECOMMERCE_AVRG_REVENUE_ALL: () => {
    return {
      tooltip: "Total avg. income per day",
      value: "all",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },

  ECOMMERCE_AVRG_REVENUE_SUCCESSFUL: () => {
    return {
      tooltip: "Avg. daily revenue from successful orders",
      value: "successful",
      measure:window.currentAccount.currency,
      addChart: false
    }
  },

  ECOMMERCE_AVRG_REVENUE_FAILED: () => {
    return {
      tooltip: "Avg. daily revenue from failed orders",
      value: "failed",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_AVRG_REVENUE_NET: () => {
    return {
      tooltip: "Avg. daily revenue from net orders",
      value: "net",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },

  ECOMMERCE_AVRG_REVENUE_IN_TRANSIT: () => {
    return {
      tooltip: "Avg. daily revenue from orders on the way",
      value: "in_transit",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  
  ECOMMERCE_AVRG_REVENUE_PENDING_PAYMENT: () => {
    return {
      tooltip: "Avg. daily possible income from orders in pending payment status",
      value: "pending_payment",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_AVRG_REVENUE_PENDING_SHIPMENT: () => {
    return {
      tooltip: "Avg. daily revenue from Pending Shipment orders",
      value: "pending_shipment",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_AVRG_REVENUE_CANCELLED: () => {
    return {
      tooltip: "Avg. daily revenue from Cancelled orders",
      value: "cancelled",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_AVRG_REVENUE_REFUNDED: () => {
    return {
      tooltip: "Avg. daily revenue Refunded orders",
      value: "refunded",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },
  ECOMMERCE_AVRG_REVENUE_DELIVERED: () => {
    return {
      tooltip: "Avg. daily revenue Delivered orders",
      value: "delivered",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },

  ECOMMERCE_AVRG_REVENUE_DELIVERED_OLD: () => {
    return {
      tooltip: "Avg. daily revenue from orders delivered min. 15 days ago",
      value: "delivered_old_15days",
      measure: window.currentAccount.currency,
      addChart: false
    }
  },





















  ECOMMERCE_REVENUE_BY_DAY: () => {
    return {

    }
  },

  ECOMMERCE_ALL_ORDERS_BY_DAY: () => {
  },

  ECOMMERCE_ORDERS_BY_DAY: () => {
    return {

    }
  },
  ECOMMERCE_REGISTERED_USERS_BY_DAY: () => {
    return {

    }
  },

  // ECOMMERCE_REGISTER_BY_DAY: ()=>{
  //   return {
  //     tooltip:"Daily Registers" ,
  //     value:"revenue",
  //     measure:'',
  //     addChart:false
  //   }
  // },

  // ECOMMERCE_REGISTER_PAY_BY_DAY: ()=>{
  //   return {
  //     tooltip:"Daily Registered Then Paid" ,
  //     value:"revenue",
  //     measure:'',
  //     addChart:false
  //   }
  // },

  // ECOMMERCE_REGISTER_ABANDONED_BY_DAY: ()=>{
  //   return {
  //     tooltip:"Daily Registered Then Abandoned" ,
  //     value:"revenue",
  //     measure:'',
  //     addChart:false
  //   }
  // },




  // ECOMMERCE_TOTAL_REVENUE: ()=>{
  //   return {
  //     tooltip:"Total Revenue" ,
  //     value:"revenue",
  //     measure:'¥',
  //     addChart:false
  //   }
  // },


  // ECOMMERCE_TOTAL_ORDER: ()=>{
  //   return {
  //     tooltip:"Total Orders" ,
  //     value:"orders",
  //     measure:'orders',
  //     addChart:false
  //   }
  // },



  // ECOMMERCE_NEW_BASKET: ()=>{
  //   return {
  //     tooltip:"New Baskets" ,
  //     value:"new_basket",
  //     measure:'baskets',
  //     addChart:false
  //   }
  // },


  // ECOMMERCE_ABANDONED_BASKET: ()=>{
  //   return {
  //     tooltip:"Abandoned Baskets" ,
  //     value:"abandoned_basket",
  //     measure:'baskets',
  //     addChart:false
  //   }
  // },




  CUSTOMER_MOST_SPENT: () => {
    return {
      customer: true,
      subValues: [
        { field: 'documented', label: 'Total sessions' },
        { field: 'interacted', label: 'Interactions' },
        { field: 'value', label: 'Life time value', prefix:window.currentAccount.currency },
        { field: 'record_score', label: 'Score' }
      ]
    }
  },



  CUSTOMER_RETURNED: () => {
    return {
      customer: true,
      subValues: [
        { field: 'documented', label: 'Total sessions' },
        { field: 'interacted', label: 'Interactions' },
        { field: 'value', label: 'Life time value', prefix:window.currentAccount.currency  },
        { field: 'record_score', label: 'Score' }
      ]
    }
  },


  TOTAL_CUSTOMERS: () => {
    return {
      tooltip: "Total customers",
      value: "total_customers",
      measure: 'customers',
      addChart: false
    }
  },

  REGISTERED_CUSTOMERS: () => {
    return {
      tooltip: "Registered customers",
      value: "registered_customers",
      measure: 'customers',
      addChart: false
    }
  },

  LOGGEDIN_CUSTOMERS: () => {
    return {
      tooltip: "Registered customers",
      value: "logged_in_customers",
      measure: 'customers',
      addChart: false
    }
  },

  LEFT_CUSTOMERS: () => {
    return {
      tooltip: "Registered customers",
      value: "left_customers",
      measure: 'customers',
      addChart: false
    }
  },

  CONVERTED_CUSTOMERS: () => {
    return {
      tooltip: "Registered customers",
      value: "converted_customers",
      measure: 'customers',
      addChart: false
    }
  },

  ORDERED_CUSTOMERS: () => {
    return {
      tooltip: "Registered customers",
      value: "ordered_customers",
      measure: 'customers',
      addChart: false
    }
  },
  NEW_CUSTOMERS: () => {
    return {
      tooltip: "New customers",
      value: "new_customers",
      measure: 'customers',
      addChart: false
    }
  },
  RETURNING_CUSTOMERS: () => {
    return {
      tooltip: "Returned customers",
      value: "returning_customers",
      measure: 'customers',
      addChart: false
    }
  },

  TWO_TIMES_BUYERS: () => {
    return {
      tooltip: "Two Times Buyers",
      value: "two_times_buyers",
      measure: 'customers',
      addChart: false
    }
  },

  ONE_TIMES_BUYERS: () => {
    return {
      tooltip: "One time buyers",
      value: "one_times_buyers",
      measure: 'customers',
      addChart: false
    }
  },




  TAG_CARD: () => {
    return {
      measure: 'customers',
      addChart: false
    }
  },



  SEGMENT_CARD: () => {
    return {
      measure: 'customers',
      addChart: false
    }
  },

  CUSTOMER_SEGMENT_BEST: () => {
    return {
      segment: true,
      subValues: [
        { field: 'documented', label: 'Audience' },
        { field: 'interacted', label: 'Interactions' },
        { field: 'revenue', label: 'Revenue', prefix:window.currentAccount.currency  }
      ]
    }
  },


  CUSTOMER_TAG_BEST: () => {
    return {
      tag: true,
      subValues: [
        { field: 'documented', label: 'Assigned' },
        { field: 'interacted', label: 'Interactions' },
        { field: 'revenue', label: 'Revenue', prefix:window.currentAccount.currency  }
      ]
    }
  },

  CUSTOMER_TOP: () => {
    return {
      customer: true,
      subValues: [
        { field: 'documented', label: 'Total sessions' },
        { field: 'interacted', label: 'Interactions' },
        { field: 'value', label: 'Life time value', prefix:window.currentAccount.currency  },
        { field: 'record_score', label: 'Score' }
      ]
    }
  },

  ECOMMERCE_PRODUCT_BEST: () => {
    return {
      customer: true,
      subValues: [
        { field: 'documented', label: 'Purchased' },
        // { field:'interacted', label:'Interactions' },
        { field: 'value', label: 'Price', prefix:window.currentAccount.currency  },
        { field: 'score', label: 'Total revenue', prefix:window.currentAccount.currency }
      ]
    }
  },


  CUSTOMERS_BY_RFM: () => {
    return {
    }
  },


  ACTIVE_CUSTOMERS: () => {
    return {
      store: CustomerListDataGridStore,
      api: CustomerProfileApi,
      grid_id: 'todays_active_customer',
      params: {
        system_filters: OverviewCardManager.getSystemFiltersForCustomer(),
        display_settings: [{ field: 'converted_users', value: true }],
        registered_users: true,
        guest_users: false
      }
    }
  },

}